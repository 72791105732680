import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import ReactMarkdown from 'react-markdown';
import { defaultReactMarkdownComponents } from './utils/markdown';

//Attempts to clean up the source string
function docusaurusToReactMarkdownString(string){

    if(string.startsWith("---")){
        string = string.replace(/^---(.|\n)*---/, "")
    }

    string = string.trim();

    if(string.match(/^sycamore tax/i)){
        string = string.replace(/^sycamore tax/i, "");
    }

    string = string.trim();

    return string;

}

export default function About() {

    const [aboutText, setAboutText] = React.useState("");
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=> {
        fetch("https://raw.githubusercontent.com/DefiantLabs/defiant-docs/main/website/docs/products/sycamore/index.md")
            .then((resp) => {
                if(resp.status !== 200){
                    throw Error("Failed to gather About data.");
                }
                return resp.text();
            })
            .then((body) => {
                let markdownString = docusaurusToReactMarkdownString(body);
                setAboutText(markdownString);
            })
            .catch(err => {
                setError("Failed to gather About data.")
            })
            .finally(() => {
                setLoading(false);
            });
    },[])

    return (
        <React.Fragment>
            {loading ?
                <Stack paddingTop={10}>
                    <CircularProgress/>
                </Stack>: (
                    error ? null: <ReactMarkdown components={defaultReactMarkdownComponents}>{aboutText}</ReactMarkdown>
                )
            }
        </React.Fragment>
    );
}
