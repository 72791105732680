import {
    Typography
} from "@mui/material";

function getTypography(variant, children){
    return <Typography variant={variant}>{children}</Typography>
}

export const defaultReactMarkdownComponents = {
    h1({node, level, children, ...props}){
        return getTypography("h1", children);
    },
    h2({node, level, children, ...props}){
        return getTypography("h2", children);
    },
    h3({node, level, children, ...props}){
        return getTypography("h3", children);
    },
    h4({node, level, children, ...props}){
        return getTypography("h4", children);
    },
    h5({node, level, children, ...props}){
        return getTypography("h5", children);
    },
    h6({node, level, children, ...props}){
        return getTypography("h6", children);
    },
    p({children, ...props}){
        return getTypography("body1", children);
    }
};
