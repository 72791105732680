import { createTheme } from '@mui/material/styles';
import { hexToRgbA } from './utils/colors';

const sycamoreColors = {
    white: "#FFFFFF",
    softYellow: "#EAE2B7",
    brightOrange: "#FCBF49",
    pureOrange: "#F77F00",
    strongRed: "#D62828",
    darkBlue: "#003049",
    darkerBlue: "#002539",
    lightBlue: "#446D7C"
};

export const sycamoreLightTheme = createTheme({
    //Used downstream for conditionals
    name: "light",
    sycamoreColors: sycamoreColors,
    palette: {
        background: {
            paper: sycamoreColors.white,
            default: sycamoreColors.white
        },
        primary: {
            main: sycamoreColors.darkBlue,
        },
        secondary: {
            main: sycamoreColors.pureOrange
        },
        error: {
            main: sycamoreColors.strongRed
        },
        warning: {
            main: sycamoreColors.brightOrange
        },
        info: {
            main: sycamoreColors.softYellow
        },
        text: {
            primary: hexToRgbA(sycamoreColors.darkBlue, 0.87),
            secondary: hexToRgbA(sycamoreColors.darkBlue, 0.6),
            disabled: hexToRgbA(sycamoreColors.darkBlue, 0.38),
        },
        appNav: {
            selected: sycamoreColors.brightOrange
        }
    },
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: sycamoreColors.darkBlue,
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined"
            },
        }
    },
    typography: {
        h1: {
            fontSize: 45
        },
        h2: {
            fontSize: 38
        },
        h3: {
            fontSize: 34
        },
        h4: {
            fontSize: 25
        },
        h5: {
            fontSize: 20
        },
        h6: {
            fontSize: 18
        },
        body1: {
            fontSize: 13
        },
        body2: {
            fontSize: 12
        }
    }
});

export const sycamoreDarkTheme = createTheme({
    //Used downstream for conditionals
    name: "dark",
    sycamoreColors: sycamoreColors,
    palette: {
        background: {
            paper: sycamoreColors.darkBlue,
            default: sycamoreColors.darkerBlue
        },
        // Change everything down here
        primary: {
            main: sycamoreColors.pureOrange,
        },
        secondary: {
            main: sycamoreColors.pureOrange
        },
        error: {
            main: sycamoreColors.strongRed
        },
        warning: {
            main: sycamoreColors.brightOrange
        },
        info: {
            main: sycamoreColors.softYellow
        },
        text: {
            primary: hexToRgbA(sycamoreColors.white, 0.9),
            secondary: hexToRgbA(sycamoreColors.white, 0.8),
            disabled: hexToRgbA(sycamoreColors.pureOrange, 0.6),
        },
        appNav: {
            selected: sycamoreColors.lightBlue
        }
    },
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: sycamoreColors.pureOrange,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& fieldset": {
                        borderColor: sycamoreColors.pureOrange
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    borderColor: sycamoreColors.pureOrange,
                    "&&::placeholder": {
                        opacity: 0.9
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined"
            },
        }
    },
    typography: {
        h1: {
            fontSize: 45
        },
        h2: {
            fontSize: 38
        },
        h3: {
            fontSize: 34
        },
        h4: {
            fontSize: 25
        },
        h5: {
            fontSize: 20
        },
        h6: {
            fontSize: 18
        },
        body1: {
            fontSize: 13
        },
        body2: {
            fontSize: 12
        }
    }
});
