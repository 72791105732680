export function getThemeValue(){
    let themeStorageValue = localStorage.getItem("storedTheme");
    if (!themeStorageValue) {
        themeStorageValue = "light";
    };
    return themeStorageValue;
}

export function setThemeValue(value){
    if (["light", "dark"].indexOf(value) !== -1){
        localStorage.setItem("storedTheme", value);
    }else{
        localStorage.setItem("storedTheme", "light");
    }
}
