import BaseChainChip from "./Base";

export default function OsmosisChip({onClick=()=>{}, selected=false}) {
    return (
        <BaseChainChip
            title="OSMO"
            subtitle="ready"
            onClick={onClick}
            selected={selected}
            logoSrc="/osmosis_logo.png"
        />
    );
}
