import * as React from 'react';
import {
    Typography,
    Chip,
} from '@mui/material';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTheme } from '@mui/material/styles';
import { hexToRgbA } from '../utils/colors.js'
import CheckIcon from '@mui/icons-material/Check';

export default function BaseChainChip({
    logoSrc="",
    onClick=()=>{},
    selected=false,
    title="",
    subtitle=""
}){

    const theme = useTheme();

    return (
        <React.Fragment>
            <Chip
                onClick={onClick}
                label={
                    <React.Fragment>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                        }}>
                            <div
                                style={{
                                    paddingTop: 2,
                                    paddingLeft: 3,
                                    display: "flex",
                                    alignItems: "end",
                                    flexDirection: "column"
                            }}>
                                <Typography
                                    variant="body2"
                                    style={{whiteSpace: 'wrap'}}
                                    component="p"
                                    fontSize={13}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    component="p"
                                    fontSize={10}
                                    marginTop={-0.5}
                                >
                                    {subtitle}
                                </Typography>
                            </div>
                            {selected ? <CheckIcon style={{paddingLeft: "5px"}}/>: null}
                        </div>
                    </React.Fragment>
                }
                icon={
                    <img width="18px"src={logoSrc} alt=""/>
                }
                sx={{
                    backgroundColor: theme.palette.background.default,
                    border: `1px solid ${hexToRgbA(theme.palette.primary.main, 1)}`,
                    borderRadius: "5px",
                    paddingTop: 2.3,
                    paddingBottom: 2.5,
                    marginLeft: 1,
                    marginRight: 1,
                    backgroundClip: "padding-box",
                    WebkitBackgroundClip: "padding-box",
                    whiteSpace: "pre-wrap",
                }}
            />
        </React.Fragment>
    );
}
