import * as React from 'react';
import Container from '@mui/material/Container';
import Header from './Header';
import Footer from './Footer';
import About from './About';
import Main from "./Main";
import { ThemeProvider } from '@mui/material/styles';
import { sycamoreDarkTheme, sycamoreLightTheme } from './theme';
import { getThemeValue, setThemeValue } from './utils/localStorage';

const appNavs = ["App", "About"];

let themeStorageValue = getThemeValue();

export default function App() {
    const [appNav, setAppNav] = React.useState(appNavs[0]);
    const [currentTheme, setTheme] = React.useState(themeStorageValue === "light" ? sycamoreLightTheme: sycamoreDarkTheme);
    const handleAppNav = (location) => {
        setAppNav(location);
    };

    const handleThemeSwitch = (on) => {
        if(on){
            setTheme(sycamoreLightTheme);
            setThemeValue("light");
        }else{
            setTheme(sycamoreDarkTheme);
            setThemeValue("dark");
        }
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={currentTheme}>
                <Header appNavs={appNavs} onNavChange={handleAppNav} onToggleTheme={handleThemeSwitch} currentAppNav={appNav}/>
                <main className="page-body" style={{backgroundColor: currentTheme.palette.background.default}}>
                    <Container maxWidth="sm" sx={{justifyContent: "center", display: "flex", paddingTop: "60px"}}>
                        <div style={{ display: appNav === "App" ? null : "none" }}>
                            <Main/>
                        </div>
                        <div style={{ display: appNav === "About" ? null : "none" }}>
                            <About/>
                        </div>
                    </Container>
                </main>
                <Footer/>
            </ThemeProvider>
        </React.Fragment>
    );
}
